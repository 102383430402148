<template>
  <div>
    <BCol
      md="12"
      class="d-flex gap-10 w-100 p-0 my-[24px] justify-end"
    >
      <MonthlyPicker
        v-model="periode"
        :month-labels="monthlabel"
        date-format="MMM YYYY"
        place-holder="Pilih Periode"
        @selected="handleDatePicker()"
      />
    </BCol>

    <div class="grid grid-cols-12 gap-[16px]">
      <Card
        v-for="(item, index) in listCount.filter(e => e.name !== 'Total sharing fee')"
        :key="index"
        :container-class="item.name === 'Need action' ? 'col-span-4' : 'col-span-2'"
        :title="item.name === 'Need action' ? 'Perlu Tindakan' : item.name"
        :content="item.count"
        :append-title-icon="item.name === 'Need action' ? 'k-info-circle-1 text-[#08A0F7]' : ''"
      >
        <template
          v-if="item.name === 'Need action'"
          #footer
        >
          <div class="bg-[#FFF2E2] text-[#AF6A13] text-[12px] mt-[10px] rounded-full py-[4px] px-[8px] flex items-center gap-[10px]">
            <span class="k-info-circle-1" />
            Silahkan konfirmasi ke Tim Finance
          </div>
        </template>
      </Card>
    </div>
    <hr>
    <div class="flex gap-[24px] justify-end">
      <BDropdown
        class="border"
        variant="none"
        toggle-class="py-0"
        no-caret
        left
      >
        <template #button-content>
          <span class="text-base font-normal flex items-center gap-[10px]">
            Talent Lead <span class="k-arrow-down-1" />
          </span>
        </template>
        <BDropdownForm class="max-h-[300px] overflow-y-auto">
          <BFormCheckbox
            v-model="filterLead"
            :name="`checkbox-all`"
            class="text-6 mb-[10px] last:mb-0"
            :value="''"
            @change="()=> {filterLead = [''], getListData()}"
          >
            Semua
          </BFormCheckbox>
          <BFormCheckbox
            v-for="(status, index) in talentLeadOptions"
            :key="index"
            v-model="filterLead"
            :name="`checkbox-${index}`"
            class="text-6 mb-[10px] last:mb-0"
            :value="status.value"
            @change="()=> {filterLead = filterLead.filter(e => e !== ''), getListData()}"
          >
            {{ status.text }}
          </BFormCheckbox>
        </BDropdownForm>
      </BDropdown>
      <BDropdown
        class="border"
        variant="none"
        toggle-class="py-0"
        no-caret
        left
      >
        <template #button-content>
          <span class="text-base font-normal flex items-center gap-[10px]">
            Role <span class="k-arrow-down-1" />
          </span>
        </template>
        <BDropdownForm>
          <BFormCheckbox
            v-for="(status, index) in roleOptions"
            :key="index"
            :name="`checkbox-${index}`"
            class="text-6"
            :value="status.value"
            @change="getListData()"
          >
            {{ status.text }}
          </BFormCheckbox>
        </BDropdownForm>
      </BDropdown>
      <BDropdown
        class="border"
        variant="white"
        toggle-class="py-0"
        no-caret
        left
      >
        <template #button-content>
          <span class="text-base font-normal flex items-center gap-[10px]">
            Status Invoice <span class="k-arrow-down-1" />
          </span>
        </template>
        <BDropdownForm>
          <BFormCheckbox
            v-for="(status, index) in statusOptions"
            :key="index"
            :name="`checkbox-${index}`"
            class="text-6 mb-[10px]"
            :value="status.value"
            @change="getListData()"
          >
            {{ status.text }}
          </BFormCheckbox>
        </BDropdownForm>
      </BDropdown>
      <div class="d-flex rounded-lg align-items-center border search-bar">
        <span
          class="k-search-normal-1 h-100 font-bold text-10 pt-[7px] align-middle mx-[5px]"
        />
        <BFormInput
          v-model="keyword"
          placeholder="Cari Nama atau No Partner"
          class="border-0"
          @input="handleSearch()"
        />
      </div>
    </div>
    <div class="d-flex py-[20px] justify-end">
      <span class="text-8 text--dark font-bold">Total Sharing Fee: {{ IDR(listCount.filter(e => e.name === 'Total sharing fee').length ? listCount.filter(e => e.name === 'Total sharing fee')[0].count : 0) }}</span>
    </div>
    <BOverlay
      id="scroll"
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 175px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <b-table-simple>
          <b-thead>
            <b-tr>
              <b-th
                v-for="head in fields"
                :key="head.key"
                :class="head.class"
                class="text-capitalize text-[#333]"
              >
                {{ head.label }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(item, indexList) in items">
              <b-tr
                v-for="(cell, index) in item.partner"
                :key="`${index}-${indexList}`"
              >
                <b-td
                  v-if="index === 0"
                  :rowspan="item.partner.length"
                  class="align-top text-[#333333] text-md"
                >
                  {{ indexList + 1 }}
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.partner.length"
                  class="align-top text-[#333333]"
                >
                  <div
                    class="flex flex-col max-w-[150px] font-medium"
                  >
                    <span class="text-md">{{ item.id_talent }} - {{ item.name }}</span>
                    <span class="text-md text-sm font-normal">{{ item.lead }}</span>
                  </div>
                </b-td>
                <b-td
                  v-if="index === 0"
                  :rowspan="item.partner.length"
                  class="align-top text-[#333333] text-md font-medium"
                >
                  {{ item.service }}
                </b-td>
                <b-td class="align-top text-[#333333] font-medium text-md">
                  {{ cell.id }} - {{ cell.name }}
                  <div class="text-muted font-normal text-sm flex items-center gap-[10px]">
                    {{ LABEL_DAY_MONTH(cell.date) }} <span
                      class="status"
                      :class="setStatus(cell.status)"
                    >{{ cell.status || '-' }}</span>
                  </div>
                </b-td>
                <b-td class="align-top text-[#333333] text-md font-medium">
                  {{ IDR(cell.salary) }}
                  <div class="text-muted font-normal text-sm">
                    ({{ IDR(cell.sharing_fee) }})
                  </div>
                </b-td>
                <b-td
                  v-if="index === 0"
                  class="align-top text-[#333333] text-md font-medium"
                >
                  {{ IDR(item.partner.reduce((a, b) => a + b.sharing_fee, 0 )) }}
                  <div class="text-muted font-normal text-sm">
                    {{ MONTH_NAME(cell.date) }}
                  </div>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </div>
    </BOverlay>
  </div>
</template>

<script>
import MonthlyPicker from 'vue-monthly-picker'
import {
  LABELMONTH, YEAR_MONTH, MONTH_NAME, LABEL_DAY_MONTH,
} from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import moment from 'moment'
import { komtimAxiosIns } from '@/libs/axios'
import Card from '../components/Card.vue'
import {
  tableSharingFee, roleOptions, statusOptions,
} from '../config'

export default {
  components: {
    MonthlyPicker,
    Card,
  },
  data() {
    return {
      periode: moment(),
      monthlabel: LABELMONTH,
      IDR,
      roleOptions,
      statusOptions,
      talentLeadOptions: [],
      listCount: [],
      items: [],
      fields: tableSharingFee,
      keyword: '',
      loading: false,
      limit: 20,
      offset: 0,
      lastData: false,
      filterLead: [''],
      MONTH_NAME,
      LABEL_DAY_MONTH,
    }
  },
  mounted() {
    this.getCount()
    this.getListData()
    this.getListTeamLead()
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')

      if ((table.scrollTop >= table.scrollHeight - table.offsetHeight - 5) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getCount() {
      this.loading = true
      this.offset = 0
      const params = `date=${YEAR_MONTH(this.periode)}`
      const url = `v1/invoices/sharing_fee/count?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.listCount = data
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getListTeamLead() {
      this.loading = true
      this.offset = 0
      const url = 'v1/invoices/sharing_fee/talent_lead'
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.talentLeadOptions = [...this.talentLeadOptions, ...data.map(e => ({
            text: e.name,
            value: e.id,
          }))]
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getListData() {
      this.loading = true
      this.offset = 0
      const params = `date=${YEAR_MONTH(this.periode)}&offset=${this.offset}&limit=${this.limit}&talent_lead=${this.filterLead.join(',')}`
      const url = `v1/invoices/sharing_fee?${params}`
      await komtimAxiosIns
        .get(url)
        .then(res => {
          this.loading = false
          const { data } = res.data
          this.items = data

          this.offset = data.length

          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(err => {
          this.loading = false
          this.alertError(err)
        })
    },
    async getNextData() {
      this.loading = true

      if (!this.lastData) {
        const params = `date=${YEAR_MONTH(this.periode)}&offset=${this.offset}&limit=${this.limit}`
        const url = `v1/invoices/sharing_fee?${params}`
        await komtimAxiosIns
          .get(url)
          .then(res => {
            this.loading = false
            const { data } = res.data
            this.items.push(...data)

            this.offset += data.length
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(err => {
            this.loading = false
            this.alertError(err)
          })
      }
    },
    handleDatePicker() {
      this.getCount()
      this.getListData()
    },
    setStatus(status) {
      switch (status) {
        case 'publish':
          return 'blue'
        case 'paid':
          return 'green'
        default:
          return 'red'
      }
    },
  },
}
</script>

<style scoped>
.status {
  height: 23px;
  padding: 4px 8px;
  border-radius: 6px;
  text-transform: capitalize;
  font-size: 12px;
}
.status.blue {
  background: #DFF3FF;
  color: #08A0F7;
}
.status.green {
  background: #DCF3EB;
  color: #34A770;
}
.status.red {
  background: #FFEDED;
  color: #E31A1A;
}
</style>
